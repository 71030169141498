<template>
  <main class="main">
    <div class="main__inner">
      <section class="section">
        <h2>Документы</h2>
        <div class="filter__form-change-wrap">
          <form
            @submit.prevent="load(1)"
            ref="filterForm"
            class="filter__form filter__form-change"
            autocomplete="off"
          >
            <div class="search">
              <div class="search__input">
                <label for="docs_search" class="search__label">Номер или название документа</label>
                <div class="search__input__wrap">
                  <input v-model="form.title.value" id="docs_search" type="text" maxlength="255" />
                  <button v-if="form.title.value" type="button" @click="resetSearchInput">
                    <CloseIcon />
                  </button>
                </div>
              </div>
              <div class="search__btn">
                <button class="btn-blue btn--small" type="submit" :disabled="loading">
                  <LoadingIndicator title="Загрузка" v-if="loading" />
                  <template v-else>Найти</template>
                </button>
              </div>
            </div>

            <div class="filter">
              <div class="filter__item filter__select">
                <label>Тип документа</label>
                <Multiselect
                  track-by="id"
                  label="title"
                  v-model="form.category_id.value"
                  :options="documentTypes"
                  placeholder="Все типы"
                  selectLabel="Выбрать ↵"
                  deselectLabel="Удалить ↵"
                  selectedLabel="Выбрано"
                  :searchable="true"
                  :allow-empty="true"
                >
                  <span slot="noOptions">Список пуст</span>
                  <span slot="noResult">Ничего не найдено.</span>
                </Multiselect>
              </div>
              <div class="filter__item filter__date">
                <label>Диапазон дат</label>
                <div class="filter__date__inputs">
                  <div class="filter__date__input">
                    <flat-pickr v-model="form.date_start.value" :config="flatPickrConfig" placeholder="дд.мм.гггг">
                    </flat-pickr>
                    <DatePickIcon />
                  </div>
                  <div class="filter__date__input">
                    <flat-pickr v-model="form.date_end.value" :config="flatPickrConfig" placeholder="дд.мм.гггг">
                    </flat-pickr>
                    <DatePickIcon />
                  </div>
                </div>
              </div>
              <div class="filter__actions">
                <a @click.prevent="resetFilterForm" href="#" class="filter__reset"> Сбросить фильтр </a>
              </div>
            </div>
          </form>
          <a
            @click.prevent="changeFilterForm"
            class="filter__change-btn"
            :class="{ hide__btn: isFilterVisible }"
            href="#"
          >
            <span v-if="isFilterVisible">Скрыть фильтр</span>
            <span v-else>Поиск и фильтр по документам</span>
            <ArrowDownIcon />
          </a>
        </div>

        <LoadingIndicator v-if="loading" title="Загрузка" />
        <template v-else>
          <p v-if="isFilter" class="result__text">
            По вашему запросу найдено совпадений: {{ this.documents.paginatorInfo ? this.documents.paginatorInfo.total : 0 }}
          </p>
          <template v-if="documents && documents.data && documents.data.length">
            <ul class="documents__list">
              <li class="documents__titles">
                <div class="documents__title document__number">Номер документа</div>
                <div class="documents__title document__title">Название</div>
                <div class="documents__title document__date">Дата документа</div>
              </li>
              <li v-for="(item, index) in documents.data" :key="index">
                <a @click.prevent="showDocumentModal(item)" class="documents__item" href="#">
                  <div v-if="item.document && item.document.number" class="document__number">
                    <span>№</span> {{ item.document.number }}
                  </div>
                  <div v-if="item.document.title" class="documents__item-title">
                    {{ item.document.title }}
                  </div>
                  <div class="document__date" v-if="item.document_date">
                    <time :time="item.document_date | robotDate">{{ item.document_date | formatDate }}</time>
                  </div>
                </a>
              </li>
            </ul>

            <PaginationComponent
              v-if="
                page !== null &&
                documents.paginatorInfo.total !== null &&
                documents.paginatorInfo.total > first
              "
              :total="Math.ceil(documents.paginatorInfo.total / first)"
              @change="paginate"
              :page="page"
            />
          </template>
          <p v-else-if="!isFilter" class="_item__title">Документы отсутствуют</p>
        </template>
      </section>
    </div>
  </main>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import Multiselect from 'vue-multiselect'
import DatePickIcon from '@/components/svg/DatePickIcon.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import ArrowDownIcon from '@/components/svg/ArrowDown.vue'
import DocumentModal from '@/components/modals/DocumentModal.vue'
import PaginationComponent from '@/components/Pagination.vue'
import PAGINATE_DOCUMENTS from '@/graphql/documents/Documents.graphql'

export default {
  name: 'DepartmentDocumentsPage',
  // при обновлении страницы почему-то запрос не делается
  // async asyncData ({ store, apollo }) {
  //   await store.dispatch('GET_DOCUMENTS_PAGE', apollo)
  // },
  data () {
    return {
      page: 1,
      first: 14,
      loading: true,
      isFilterVisible: false,
      flatPickrConfig: {
        locale: Russian,
        enableTime: true,
        dateFormat: 'd.m.Y H:i',
        disableMobile: true
      },
      filterData: {},
      form: {
        title: {
          value: null,
          message: null
        },
        category_id: {
          value: null,
          message: null
        },
        date_start: {
          value: null,
          message: null
        },
        date_end: {
          value: null,
          message: null
        }
      }
    }
  },
  created () {
    this.$store.dispatch('GET_DOCUMENTS_PAGE', this.$apollo.provider).finally(() => {
      this.loading = false
    })
  },
  computed: {
    documents () {
      return this.$store.state.documents
    },
    documentTypes () {
      if (this.$store.state.document_types && this.$store.state.document_types.length) {
        return this.$store.state.document_types
      }
      return []
    },
    isFilter () {
      const keys = Object.keys(this.filterData)
      return keys && keys.length
    }
  },
  methods: {
    changeFilterForm () {
      const filter = this.$refs.filterForm
      filter.style.display = this.isFilterVisible ? 'none' : 'flex'
      this.isFilterVisible = !this.isFilterVisible
    },
    showDocumentModal (doc) {
      this.$store.state._modals.push({
        component: DocumentModal,
        options: {
          document: doc
        }
      })
    },
    paginate (e) {
      this.page = e
      this.load()
      window.scrollTo(0, 0)
    },
    async load (page) {
      if (!this.loading) {
        this.loading = true
        this.filterData = {}
        Object.keys(this.form).forEach((key) => {
          if (key === 'category_id') {
            if (this.form[key].value) this.filterData.categories = [this.form[key].value.id]
          } else if (this.form[key].value !== null) this.filterData[key] = this.form[key].value
        })
        if (page) this.page = page
        await this.$apollo.provider.clients.dept
          .query({
            query: PAGINATE_DOCUMENTS,
            variables: Object.assign({
              ...this.filterData,
              page: this.page,
              first: this.first
            })
          })
          .then(({ data }) => {
            this.loading = false
            if (data.JoinDocuments) {
              this.$store.commit('documents', data.JoinDocuments)
            }
            if (data.category_documents) {
              this.$store.commit('document_types', data.category_documents)
            }
            if (data.this_department) {
              this.$store.commit('this_department', data.this_department)
            }
          })
          .catch((err) => {
            this.loading = false
            console.error(err)
          })
      }
    },
    /**
     * Сброс фильтра
     */
    resetFilterForm () {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null
      })
      this.paginate(1)
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput () {
      this.form.title.value = null
    }
  },
  metaInfo () {
    return this.$seo(
      'common',
      'Официальный сайт Главы города Махачкалы',
      '',
      '',
      'Официальный сайт Главы города Махачкалы',
      '',
      ''
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
  components: {
    FlatPickr,
    Multiselect,
    DatePickIcon,
    LoadingIndicator,
    PaginationComponent,
    CloseIcon,
    ArrowDownIcon
  }
}
</script>

<style lang="stylus">
@import '~flatpickr/dist/flatpickr.min.css'
@import '~vue-multiselect/dist/vue-multiselect.min.css'
@import "~@/styles/parts/documents.styl"
</style>
