<template>
  <div class="document__modal">
    <button class="modal__close" @click="$emit('close')">
      <CloseIcon />
    </button>
    <h2 class="dm__title">Документы Администрации города Махачкалы</h2>
    <div class="dm__category dm__text">
      {{ options.document.category.title }}
    </div>
    <div class="dm__number-date dm__text">
      <span v-if="options.document.document.number">№ {{ options.document.document.number }}</span>
      <span v-if="options.document.document_date">
        от {{ options.document.document_date | formatDate }} г.</span
      >
    </div>

    <a
      :href="$store.state.main_api + options.document.document.url"
      class="dm__document-title dm__text dm__link"
      target="_blank"
      v-if="options.document.document.title"
    >
      {{ options.document.document.title }}
    </a>

    <ul class="dm__files row-thin">
      <li class="col-12-thin dm__file">
        <div class="dm__file-icon">
          <DocumentIcon />
        </div>
        <div class="dm__file-info">
          <a
            :href="$store.state.main_api + options.document.document.url"
            class="dm__file-title dm__link"
            target="_blank"
            download
            v-if="options.document.document.url && options.document.document.origin_name"
          >
            {{ options.document.document.origin_name }}
          </a>
          <div class="dm__file-size">
            <span v-if="options.document.document.type" class="dm__file-type">{{
              options.document.document.type
            }}</span>
            <span v-if="options.document.document.size"> ({{ options.document.document.size }})</span>
          </div>
        </div>
      </li>

      <li v-for="(item, index) in options.document.child_documents" :key="index" class="col-12-thin dm__file">
        <div class="dm__file-icon">
          <DocumentIcon />
        </div>
        <div class="dm__file-info">
          <a
            :href="$store.state.main_api + item.document.url"
            class="dm__file-title dm__link"
            target="_blank"
            download
            v-if="item.document.title"
          >
            {{ item.document.title }}
          </a>
          <div class="dm__file-size">
            <span class="dm__file-type" v-if="item.document.type">{{ item.document.type }}</span>
            <span v-if="item.document.size"> ({{ item.document.size }})</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import DocumentIcon from '@/components/svg/DocumentIcon.vue'
import CloseIcon from 'components/svg/CloseIcon.vue'

export default {
  name: 'DocumentModal',
  props: {
    options: {
      type: Object
    }
  },
  components: {
    CloseIcon,
    DocumentIcon
  }
}
</script>

<style lang="stylus">
.document__modal {
  width 95vw
  max-width 1190px
  overflow hidden
  padding 80px
  color var(--color_dark)
  background: var(--color_white);
  box-shadow: 0 30px 60px var(--color_black_o15);
  border-radius: var(--radius);
  +below(1200px) {
    max-width 960px
  }
  +below(768px) {
    max-width 576px
    padding 48px 40px 40px
  }
  +below(540px) {
    width auto
  }
  +below(420px) {
    padding 16px
  }

  .dm {
    &__text {
      font-size: 1.125em
      line-height: 1.35em
      font-weight: 500;
      +below(768px) {
        font-size 1em
        line-height 20px
      }
    }

    &__link {
      display block
      color var(--color_blue_dark)
      transition 0.2s

      &:hover {
        color var(--color_blue)
      }
    }

    &__title {
      font-weight: 700
      font-size: 1.875em
      line-height 1.2em
      margin-top 0
      +below(768px) {
        font-weight: 500;
        font-size: 1.125em
        line-height: 24px;
        margin-bottom: 24px;
      }
      +below(420px) {
        padding-right 40px
      }
    }

    &__category {
      padding-bottom: 6px;
      margin-bottom: 32px;
      border-bottom 1px solid var(--main_border_color)
      +below(768px) {
        margin-bottom: 24px;
      }
      +below(420px) {
        margin-bottom: 16px;
      }
    }

    &__number-date {
      font-weight: 700
      margin-bottom: 32px;
      +below(768px) {
        margin-bottom: 24px;
      }
      +below(420px) {
        margin-bottom: 16px;
      }
    }

    &__document-title {
      margin-bottom 32px
      +below(768px) {
        margin-bottom: 24px;
      }
      +below(420px) {
        margin-bottom: 16px;
      }
      +below(320px) {
        font-size 0.875em
        line-height 20px
      }
    }

    &__file {
      display flex

      &-icon {
        margin-right: 24px;
        +below(768px) {
          margin-right: 16px;
        }
        +below(420px) {
          margin-right: 8px;
        }
      }

      &-title {
        margin-bottom: 4px;
        +below(420px) {
          font-size 0.875em
          line-height 20px
        }
      }

      &-size {
        font-size: 0.875em
        line-height: 1.43em
      }

      &-type {
        text-transform: uppercase;
      }
    }
  }
}
</style>
